import React from 'react';
import creditCart from '../../images/credit-card-icon.png';

function Characteristics() {
  return (
    <div className="Content__characteristics">
        <div className="container">
            <div className="Content__characteristics--container">
                <div className="Content__characteristics--item">
                    <div className="Content__characteristics--icon">
                        <img src={creditCart} alt="" />
                    </div>
                    <div className="Content__characteristics--details">
                        <div className="Content__characteristics--details-title">
                            <h4>Fácil de participar</h4>
                        </div>
                        <div className="Content__characteristics--details-description">
                            <p>Ingresa, registrate y participa.</p>
                        </div>
                    </div>
                </div>
                <div className="Content__characteristics--item">
                    <div className="Content__characteristics--icon">
                        <img src={creditCart} alt="" />
                    </div>
                    <div className="Content__characteristics--details">
                        <div className="Content__characteristics--details-title">
                            <h4>Todo medio de pago</h4>
                        </div>
                        <div className="Content__characteristics--details-description">
                            <p>Contamos con una completa pasarela de pago.</p>
                        </div>
                    </div>
                </div>
                <div className="Content__characteristics--item">
                    <div className="Content__characteristics--icon">
                        <img src={creditCart} alt="" />
                    </div>
                    <div className="Content__characteristics--details">
                        <div className="Content__characteristics--details-title">
                            <h4>Juega seguro</h4>
                        </div>
                        <div className="Content__characteristics--details-description">
                            <p>Trabajamos con los más altos estándares de seguridad para que juegues seguro.</p>
                        </div>
                    </div>
                </div>
                <div className="Content__characteristics--item">
                    <div className="Content__characteristics--icon">
                        <img src={creditCart} alt="" />
                    </div>
                    <div className="Content__characteristics--details">
                        <div className="Content__characteristics--details-title">
                            <h4>Todo tipo de productos</h4>
                        </div>
                        <div className="Content__characteristics--details-description">
                            <p>Puedes encontrar, productos nuevos como usados.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export { Characteristics }