import { React, useState } from 'react';
import logo from '../images/serifa-logo.png';
import arrowDown from '../images/nav-arrow-down.png';
import profileIcon from '../images/profile-icon.png';
import whiteProfileIcon from '../images/white-account.png';
import searchIcon from '../images/search-icon.png';
import cartIcon from '../images/cart-icon.png';
import barsMobile from '../images/menu.png';
import otherRaffle1 from '../images/other-raffle-1.png';
import otherRaffle2 from '../images/other-raffle-2.png';
import { Link } from 'react-router-dom';

function Header() {

    const [menuOpen, setMenuOpen] = useState(false);
    const [showResume, setShowResume] = useState(false);

    const handleToggleResume = () => {
        setShowResume(!showResume);
    };

    const handleMenuClick = () => {
        setMenuOpen(!menuOpen);
    };

    const [showDropdown, setShowDropdown] = useState(false);

    const handleHover = () => {
      setShowDropdown(true);
    };
  
    const handleLeave = () => {
      setShowDropdown(false);
    };

  return (
    <>
        <header className="Header">
            <div className="Header__topbar">
                <div className="container">
                    <div className="Header__topbar--text">
                        <p><strong>Participa por esta causa:</strong> Fundación del cancer necesita ayuda para recontruir su centro de entretención para familias </p>
                    </div>
                </div>
            </div>
            <div className="Header__bottom">
                <div className="container">
                    <div className="Header__bottom--container">
                        <div className="Header__bottom--logo">
                            <Link to="/">
                                <img src={logo} alt="main-logo" />
                            </Link>
                        </div>
                        <div className="Header__bottom--navbar">
                            <ul>
                                <li><Link to="/">Inicio</Link></li>
                                <li><Link to="/about">Nosotros</Link></li>
                                <li><Link to="/foundations">Fundaciones</Link></li>
                                <li><Link to="/be-partner">Socios SeRifa</Link></li>
                                <li onMouseEnter={handleHover} onMouseLeave={handleLeave}>
                                    <Link to="#">Transparencia <img src={arrowDown} alt="" /></Link>
                                    <div className={`dropdownContent ${showDropdown ? 'dropDownActive' : ''}`}>
                                        <ul>
                                            <li><Link to="">Bases legales (PDF)</Link></li>
                                            <li><Link to="/terms-and-conditions">Términos y condiciones</Link></li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="Header__bottom--actions">
                            <div className="Header__bottom--actions-action accountAction">
                                <p>
                                    <img src={profileIcon} alt="" />
                                    <span><Link to="/login">Clientes</Link></span>
                                </p>
                                <a href="https://panel.serifa.app/" target="blank" className="sociosButton">
                                    <img src={whiteProfileIcon} alt="" />
                                    <span>Socios</span>
                                </a>
                            </div>
                            <div className="Header__bottom--actions-action">
                                <img src={searchIcon} alt="" />
                            </div>
                            <div className="Header__bottom--actions-action" onClick={handleToggleResume}>
                                <Link to="#">
                                    <img src={cartIcon} alt="" />
                                    <span>1</span>
                                </Link>
                            </div>
                        </div>
                        <div className="Header__bottom--mobileBars" onClick={handleMenuClick}>
                            <img src={barsMobile} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <div className="Content__mobileMenu">
            <div className="container">
                <div className={`Content__mobileMenu--container ${menuOpen ? "menuActive" : ""}`}>
                    <div className="Content__mobileMenu--nav">
                        <ul>
                            <li className="linkMenu linkActive"><i className="fas fa-bars" aria-hidden="true"></i><span>Menu</span></li>
                        </ul>
                    </div>
                    <div className="Content__mobileMenu--mainNavs">
                        <div className="Content__mobileMenu--mainNavs-nav bodyActive2">
                            <ul className="menu">
                                <li><Link to="/">Inicio</Link></li>
                                <li><Link to="/about">Nosotros</Link></li>
                                <li><Link to="/foundations">Fundaciones</Link></li>
                                <li><Link to="/be-partner">Socios SeRifa</Link></li>
                                <li><Link to="">Bases legales (PDF)</Link></li>
                                <li><Link to="/terms-and-conditions">Términos y condiciones</Link></li>
                            </ul>
                        </div>
                        <div className="Content__mobileMenu--mainNavs-nav">
                            <ul className="categories">
                                <li className="mundoGamer"><span>Mundo Gamer</span> <i className="fas fa-chevron-right" aria-hidden="true"></i></li>
                            </ul>
                        </div>
                    </div>
                    <div className="Content__mobileMenu--categoriesBody">
                        <div className="Content__menu--content-body">
                            <ul className="mundoGamerNav">
                                <h4 className="listTitle"><a href="#">Mundo Gamer</a></h4>
                                <li><a href="#">Mouse</a></li>
                                <li><a href="#">Mousepad</a></li>
                                <li><a href="#">Teclados</a></li>
                                <li><a href="#">Reposamuñecas</a></li>
                                <li><a href="#">Kit Gamer</a></li>
                                <li><a href="#">Microfonos</a></li>
                                <li><a href="#">Audifonos</a></li>
                                <li><a href="#">Parlantes</a></li>
                                <li><a href="#">Soportes de Audifonos</a></li>
                                <li><a href="#">Sillas</a></li>
                                <li><a href="#">Controles</a></li>
                                <li><a href="#">Lentes</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="Header__bottom--actions-action mobileAccount">
                        <p>
                            <img src={profileIcon} alt="" />
                            <span><Link to="/login">Clientes</Link></span>
                        </p>
                        <a href="https://panel.serifa.app/" target="blank" className="sociosButton">
                            <img src={whiteProfileIcon} alt="" />
                            <span>Socios</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div className={`Content__resume ${showResume ? "resumeActive" : ""}`}>
            <div className="Content__resume--container">
                <div className="Content__resume--title">
                    <h2>Resumen</h2>
                </div>
                <div className="Content__resume--choosenNumbers">
                    <div className="Content__resume--choosenNumbers-title">
                        <p>Números elegidos</p>
                    </div>
                    <div className="Content__resume--choosenNumbers-table">
                        <div className="resume__choosenNumbers--table-number"><p>1</p></div>
                        <div className="resume__choosenNumbers--table-number chosenNumber"><p>2</p></div>
                        <div className="resume__choosenNumbers--table-number soldOutNumber"><p>3</p></div>
                        <div className="resume__choosenNumbers--table-number soldOutNumber"><p>4</p></div>
                        <div className="resume__choosenNumbers--table-number soldOutNumber"><p>5</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>6</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>7</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>8</p></div>
                        <div className="resume__choosenNumbers--table-number soldOutNumber"><p>9</p></div>
                        <div className="resume__choosenNumbers--table-number soldOutNumber"><p>10</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>11</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>12</p></div>
                        <div className="resume__choosenNumbers--table-number chosenNumber"><p>13</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>14</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>15</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>16</p></div>
                        <div className="resume__choosenNumbers--table-number chosenNumber"><p>17</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>18</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>19</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>20</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>21</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>22</p></div>
                        <div className="resume__choosenNumbers--table-number soldOutNumber"><p>23</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>24</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>25</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>26</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>27</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>28</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>29</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>30</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>31</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>32</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>33</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>34</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>35</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>36</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>37</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>38</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>39</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>40</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>41</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>42</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>43</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>44</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>45</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>46</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>47</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>48</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>49</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>50</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>51</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>52</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>53</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>54</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>55</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>56</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>57</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>58</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>59</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>60</p></div>
                    </div>
                </div>
                <div className="Content__resume--message">
                    <p>* Recuerda revisar tus números antes de hacer el pago</p>
                </div>
                <div className="Content__resume--totalNumbers">
                    <div className="Content__resume--totalNumbers-title">
                        <h4>Resumen</h4>
                    </div>
                    <div className="Content__resume--totalNumbers-table">
                        <div className="resume__totalNumbers--table-heading">
                            <p>Total de números elegidos <strong>250</strong></p>
                        </div>
                        <div className="resume__totalNumbers--table-button">
                            <button>Elegir número a la suerte</button>
                        </div>
                    </div>
                </div>
                <div className="Content__resume--otherRaffles">
                    <div className="Content__resume--otherRaffles-title">
                        <h4>Otras Rifas </h4>
                    </div>
                    <div className="Content__resume--otherRaffles-raffle">
                        <img src={otherRaffle1} alt="" />
                    </div>
                    <div className="Content__resume--otherRaffles-raffle">
                        <img src={otherRaffle2} alt="" />
                    </div>
                </div>
            </div>
        </div>
        <div className={`overlay ${menuOpen ? "overlayActive" : ""}`} onClick={handleMenuClick}></div>
        <div className={`overlay ${showResume ? "overlayActive" : ""}`} onClick={handleToggleResume}></div>
    </>
    
  )
}

export { Header }