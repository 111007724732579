import React from 'react';
import aboutIcon from '../images/about-icon.png';
import missionBanner from '../images/our-mission-banner.png';
import emotionTransparencyBanner from '../images/emotion-transparency.png';
import innovationPasionBanner from '../images/innovation-pasion.png';

function About() {
  return (
        <div className="Content__about">
            <div className="container">
                <div className="Content__about--container">
                    <div className="Content__about--item">
                        <div className="Content__about--item-details">
                            <div className="about__item--details-icon">
                                <img src={aboutIcon} alt="" />
                            </div>
                            <div className="about__item--details-title">
                                <h2>Nuestra misión</h2>
                            </div>
                            <div className="about__item--details-description">
                                <p>En "Se Rifa," nuestra misión es pura, brindar a nuestros clientes una experiencia verdaderamente única, mientras contribuimos a causas sociales que realmente importan. Nuestro propósito es convertirnos en líderes indiscutibles del mercado, dejando un impacto significativo y generando oportunidades de ingresos para nuestros afiliados. Valores como la experiencia, la innovación, el compañerismo, el impacto social, la excelencia y la confianza son los cimientos sobre los que construimos cada paso que damos.</p>
                            </div>
                        </div>
                        <div className="Content__about--item-banner">
                            <img src={missionBanner} alt="" />
                        </div>
                    </div>
                    <div className="Content__about--item">
                        <div className="Content__about--item-banner">
                            <img src={emotionTransparencyBanner} alt="" />
                        </div>
                        <div className="Content__about--item-details">
                            <div className="about__item--details-icon">
                                <img src={aboutIcon} alt="" />
                            </div>
                            <div className="about__item--details-title">
                                <h2>Emoción y Transparencia</h2>
                            </div>
                            <div className="about__item--details-description">
                                <p>En "Se Rifa," nos comprometemos a proporcionar la emoción auténtica de las rifas, todo respaldado por un compromiso inquebrantable con la transparencia. Aquí, nuestros clientes tienen la oportunidad de adquirir productos atractivos de una manera que realmente resulta divertida.</p>
                            </div>
                        </div>
                    </div>
                    <div className="Content__about--item">
                        <div className="Content__about--item-details">
                            <div className="about__item--details-icon">
                                <img src={aboutIcon} alt="" />
                            </div>
                            <div className="about__item--details-title">
                                <h2>Innovación y pasión</h2>
                            </div>
                            <div className="about__item--details-description">
                                <p>En "Se Rifa," nuestra misión es pura, brindar a nuestros clientes una experiencia verdaderamente única, mientras contribuimos a causas sociales que realmente importan. Nuestro propósito es convertirnos en líderes indiscutibles del mercado, dejando un impacto significativo y generando oportunidades de ingresos para nuestros afiliados. Valores como la experiencia, la innovación, el compañerismo, el impacto social, la excelencia y la confianza son los cimientos sobre los que construimos cada paso que damos.</p>
                            </div>
                        </div>
                        <div className="Content__about--item-banner">
                            <img src={innovationPasionBanner} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
  )
}

export { About }