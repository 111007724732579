import './App.css';
import './css/components/styles.css'
import { Header } from './components/Header';
import { Home } from './pages/Home';
import { Footer } from './components/Footer';
import { Login } from './pages/Login';
import { Register } from './pages/Register';
import { About } from './pages/About';
import { BePartner } from './pages/BePartner';
import { Foundations } from './pages/Foundations';
import { Resume } from './components/Resume';
import { SingleRaffle } from './pages/SingleRaffle';
import { TermsAndConditions } from './pages/TermsAndConditions';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <>
      <Router>
        <Header />
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/about" element={<About />} />
            <Route path="/be-partner" element={<BePartner />} />
            <Route path="/foundations" element={<Foundations />} />
            <Route path="/resume" element={<Resume />} />
            <Route path="/single-raffle" element={<SingleRaffle />} />
            <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
