import React from 'react';
import leftArrow from '../../images/left-arrow.png'
import rightArrow from '../../images/right-arrow.png'

function Testimonials() {
  return (
    <div className="Content__testimonials">
        <div className="container">
            <div className="Content__testimonials--container">
                <div className="Content__testimonials--title">
                    <h2>Ellos ya ganaron</h2>
                </div>
                <div className="Content__testimonials--slider">
                    <div className="Content__testimonials--slide">
                        <div className="Content__testimonials--slide-text">
                            <p>Fue todo muy expedito, solo elegi el producto que queria, mis números y participe, me llego un correo con el sorteo y gane, se contactaron conmigo y mi premio llego sin problemas :D </p>
                        </div>
                        <div className="Content__testimonials--slide-name">
                            <h4>Max Sulivan - IPHONE 14</h4>
                        </div>
                    </div>
                </div>
                <div className="Content__testimonials--arrows">
                    <div className="Content__testimonials--arrows-leftArrow">
                        <img src={leftArrow} alt="" />
                    </div>
                    <div className="Content__testimonials--arrows-rightArrow">
                        <img src={rightArrow} alt="" />
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export { Testimonials }