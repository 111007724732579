import React from 'react';
import vivirFelizIcon from '../images/vivir-feliz.png';
import reintegraIcon from '../images/reintegra.png';
import todosIcon from '../images/todos.png';
import mujerIcon from '../images/mujer.png';
import cristoCalleIcon from '../images/cristo-de-la-calle.png';
import huellaIcon from '../images/huella-animal.png';

function Foundations() {
  return (
        <div className="Content__foundations">
            <div className="container">
                <div className="Content__foundations--heading">
                    <div className="Content__foundations--heading-icon">
                        <img src="./images/foundations-icon.png" alt="" />
                    </div>
                    <div className="Content__foundations--heading-title">
                        <h2>Fundaciones que ayudamos</h2>
                    </div>
                    <div className="Content__foundations--heading-description">
                        <p>En "Se Rifa," construimos puentes entre fundaciones, empresas y personas comprometidas con el bien común. Potenciamos tu labor social, multiplicando su impacto. Únete a nuestra comunidad y juntos creemos un mundo mejor, mejorando tanto la sociedad como el planeta. Tu compromiso es el motor del cambio.</p>
                    </div>
                    <div className="Content__foundations--heading-slogan">
                        <p>¡Haz la diferencia con nosotros! </p>
                    </div>
                </div>
                <div className="Content__foundations--slider">
                    <div className="Content__foundations--slider-slide">
                        <img src={vivirFelizIcon} alt="" />
                    </div>
                    <div className="Content__foundations--slider-slide">
                        <img src={reintegraIcon} alt="" />
                    </div>
                    <div className="Content__foundations--slider-slide">
                        <img src={todosIcon} alt="" />
                    </div>
                    <div className="Content__foundations--slider-slide">
                        <img src={mujerIcon} alt="" />
                    </div>
                    <div className="Content__foundations--slider-slide">
                        <img src={cristoCalleIcon} alt="" />
                    </div>
                    <div className="Content__foundations--slider-slide">
                        <img src={huellaIcon} alt="" />
                    </div>
                    <div className="Content__foundations--slider-slide">
                        <img src={todosIcon} alt="" />
                    </div>
                    <div className="Content__foundations--slider-slide">
                        <img src={reintegraIcon} alt="" />
                    </div>
                </div>
            </div>
        </div>
  )
}

export { Foundations }