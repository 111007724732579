import React from 'react';
import logo from '../images/serifa-logo.png';
import phoneIcon from '../images/phone-icon.png';
import addressIcon from '../images/address-icon.png';
import envelopeIcon from '../images/envelope-icon.png';
import mastercardIcon from '../images/mastercard.png';
import visaIcon from '../images/visa.png';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer className="Footer">
        <div className="container">
            <div className="Footer__container">
                <div className="Footer__logo">
                    <img src={logo} alt="" />
                    <p>Todos los derechos reservados serifa 2023</p>
                </div>
                <div className="Footer__item">
                    <h4>Lo que debes saber</h4>
                    <ul>
                        <li><Link to="/about">Nosotros</Link></li>
                        <li><Link to="/foundations">Fundaciones</Link></li>
                        <li><Link to="/be-partner">Inscríbete como socio</Link></li>
                    </ul>
                </div>
                <div className="Footer__item">
                    <h4>Transparencia</h4>
                    <ul>
                        <li><Link to="/terms-and-conditions">Términos y condiciones</Link></li>
                        <li><Link to="#">Bases legales (pdf)</Link></li>
                    </ul>
                </div>
                <div className="Footer__item">
                    <h4>Contáctanos</h4>
                    <ul>
                        <li><a href="tel:+564805550103"><img src={phoneIcon} alt="" /> (480) 555-0103</a></li>
                        <li><a href="/"><img src={addressIcon} alt="" /> 4517 Washington Ave. Manchester, Kentucky 39495</a></li>
                        <li><a href="mailto:seriga@example.com"><img src={envelopeIcon} alt="" /> seriga@example.com</a></li>
                    </ul>
                </div>
                <div className="Footer__item">
                    <h4>Medios de pago</h4>
                    <ul>
                        <li><img src={mastercardIcon} alt="" /></li>
                        <li><img src={visaIcon} alt="" /></li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
  )
}

export { Footer }