import React from 'react'

function AvailableNumbers() {
  return (
        <div className="Content__availableNumbers">
            <div className="container">
                <div className="Content__availableNumbers--container">
                    <div className="Content__availableNumbers--title">
                        <h2>Números disponibles</h2>
                    </div>
                    <div className="Content__availableNumbers--table">
                        <div className="Content__availableNumbers--table-number"><p>1</p></div>
                        <div className="Content__availableNumbers--table-number chosenNumber"><p>2</p></div>
                        <div className="Content__availableNumbers--table-number soldOutNumber"><p>3</p></div>
                        <div className="Content__availableNumbers--table-number soldOutNumber"><p>4</p></div>
                        <div className="Content__availableNumbers--table-number soldOutNumber"><p>5</p></div>
                        <div className="Content__availableNumbers--table-number"><p>6</p></div>
                        <div className="Content__availableNumbers--table-number"><p>7</p></div>
                        <div className="Content__availableNumbers--table-number"><p>8</p></div>
                        <div className="Content__availableNumbers--table-number soldOutNumber"><p>9</p></div>
                        <div className="Content__availableNumbers--table-number soldOutNumber"><p>10</p></div>
                        <div className="Content__availableNumbers--table-number"><p>11</p></div>
                        <div className="Content__availableNumbers--table-number"><p>12</p></div>
                        <div className="Content__availableNumbers--table-number chosenNumber"><p>13</p></div>
                        <div className="Content__availableNumbers--table-number"><p>14</p></div>
                        <div className="Content__availableNumbers--table-number"><p>15</p></div>
                        <div className="Content__availableNumbers--table-number"><p>16</p></div>
                        <div className="Content__availableNumbers--table-number chosenNumber"><p>17</p></div>
                        <div className="Content__availableNumbers--table-number"><p>18</p></div>
                        <div className="Content__availableNumbers--table-number"><p>19</p></div>
                        <div className="Content__availableNumbers--table-number"><p>20</p></div>
                        <div className="Content__availableNumbers--table-number"><p>21</p></div>
                        <div className="Content__availableNumbers--table-number"><p>22</p></div>
                        <div className="Content__availableNumbers--table-number soldOutNumber"><p>23</p></div>
                        <div className="Content__availableNumbers--table-number"><p>24</p></div>
                        <div className="Content__availableNumbers--table-number"><p>25</p></div>
                        <div className="Content__availableNumbers--table-number"><p>26</p></div>
                        <div className="Content__availableNumbers--table-number"><p>27</p></div>
                        <div className="Content__availableNumbers--table-number"><p>28</p></div>
                        <div className="Content__availableNumbers--table-number"><p>29</p></div>
                        <div className="Content__availableNumbers--table-number"><p>30</p></div>
                        <div className="Content__availableNumbers--table-number"><p>31</p></div>
                        <div className="Content__availableNumbers--table-number"><p>32</p></div>
                        <div className="Content__availableNumbers--table-number"><p>33</p></div>
                        <div className="Content__availableNumbers--table-number"><p>34</p></div>
                        <div className="Content__availableNumbers--table-number"><p>35</p></div>
                        <div className="Content__availableNumbers--table-number"><p>36</p></div>
                        <div className="Content__availableNumbers--table-number"><p>37</p></div>
                        <div className="Content__availableNumbers--table-number"><p>38</p></div>
                        <div className="Content__availableNumbers--table-number"><p>39</p></div>
                        <div className="Content__availableNumbers--table-number"><p>40</p></div>
                        <div className="Content__availableNumbers--table-number"><p>41</p></div>
                        <div className="Content__availableNumbers--table-number"><p>42</p></div>
                        <div className="Content__availableNumbers--table-number"><p>43</p></div>
                        <div className="Content__availableNumbers--table-number"><p>44</p></div>
                        <div className="Content__availableNumbers--table-number"><p>45</p></div>
                        <div className="Content__availableNumbers--table-number"><p>46</p></div>
                        <div className="Content__availableNumbers--table-number"><p>47</p></div>
                        <div className="Content__availableNumbers--table-number"><p>48</p></div>
                        <div className="Content__availableNumbers--table-number"><p>49</p></div>
                        <div className="Content__availableNumbers--table-number"><p>50</p></div>
                        <div className="Content__availableNumbers--table-number"><p>51</p></div>
                        <div className="Content__availableNumbers--table-number"><p>52</p></div>
                        <div className="Content__availableNumbers--table-number"><p>53</p></div>
                        <div className="Content__availableNumbers--table-number"><p>54</p></div>
                        <div className="Content__availableNumbers--table-number"><p>55</p></div>
                        <div className="Content__availableNumbers--table-number"><p>56</p></div>
                        <div className="Content__availableNumbers--table-number"><p>57</p></div>
                        <div className="Content__availableNumbers--table-number"><p>58</p></div>
                        <div className="Content__availableNumbers--table-number"><p>59</p></div>
                        <div className="Content__availableNumbers--table-number"><p>60</p></div>
                    </div>
                    <div className="Content__availableNumbers--meanings">
                        <div className="Content__availableNumbers--meanings-meaning">
                            <div className="availableNumbers__meanings--meaning-icon chosenNumber"></div>
                            <div className="availableNumbers__meanings--meaning-text">
                                <span>Números elegidos</span>
                            </div>
                        </div>
                        <div className="Content__availableNumbers--meanings-meaning">
                            <div className="availableNumbers__meanings--meaning-icon"></div>
                            <div className="availableNumbers__meanings--meaning-text">
                                <span>Números disponibles</span>
                            </div>
                        </div>
                        <div className="Content__availableNumbers--meanings-meaning">
                            <div className="availableNumbers__meanings--meaning-icon soldOutNumber"></div>
                            <div className="availableNumbers__meanings--meaning-text">
                                <span>Números agotados</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  )
}

export { AvailableNumbers }