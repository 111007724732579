import React from 'react';
import otherRaffle1 from '../images/other-raffle-1.png';
import otherRaffle2 from '../images/other-raffle-2.png';

function Resume() {
  return (
        <div className="Content__resume">
            <div className="Content__resume--container">
                <div className="Content__resume--title">
                    <h2>Resumen</h2>
                </div>
                <div className="Content__resume--choosenNumbers">
                    <div className="Content__resume--choosenNumbers-title">
                        <p>Números elegidos</p>
                    </div>
                    <div className="Content__resume--choosenNumbers-table">
                        <div className="resume__choosenNumbers--table-number"><p>1</p></div>
                        <div className="resume__choosenNumbers--table-number chosenNumber"><p>2</p></div>
                        <div className="resume__choosenNumbers--table-number soldOutNumber"><p>3</p></div>
                        <div className="resume__choosenNumbers--table-number soldOutNumber"><p>4</p></div>
                        <div className="resume__choosenNumbers--table-number soldOutNumber"><p>5</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>6</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>7</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>8</p></div>
                        <div className="resume__choosenNumbers--table-number soldOutNumber"><p>9</p></div>
                        <div className="resume__choosenNumbers--table-number soldOutNumber"><p>10</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>11</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>12</p></div>
                        <div className="resume__choosenNumbers--table-number chosenNumber"><p>13</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>14</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>15</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>16</p></div>
                        <div className="resume__choosenNumbers--table-number chosenNumber"><p>17</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>18</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>19</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>20</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>21</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>22</p></div>
                        <div className="resume__choosenNumbers--table-number soldOutNumber"><p>23</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>24</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>25</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>26</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>27</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>28</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>29</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>30</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>31</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>32</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>33</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>34</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>35</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>36</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>37</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>38</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>39</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>40</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>41</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>42</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>43</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>44</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>45</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>46</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>47</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>48</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>49</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>50</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>51</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>52</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>53</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>54</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>55</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>56</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>57</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>58</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>59</p></div>
                        <div className="resume__choosenNumbers--table-number"><p>60</p></div>
                    </div>
                </div>
                <div className="Content__resume--message">
                    <p>* Recuerda revisar tus números antes de hacer el pago</p>
                </div>
                <div className="Content__resume--totalNumbers">
                    <div className="Content__resume--totalNumbers-title">
                        <h4>Resumen</h4>
                    </div>
                    <div className="Content__resume--totalNumbers-table">
                        <div className="resume__totalNumbers--table-heading">
                            <p>Total de números elegidos <strong>250</strong></p>
                        </div>
                        <div className="resume__totalNumbers--table-button">
                            <button>Elegir número a la suerte</button>
                        </div>
                    </div>
                </div>
                <div className="Content__resume--otherRaffles">
                    <div className="Content__resume--otherRaffles-title">
                        <h4>Otras Rifas </h4>
                    </div>
                    <div className="Content__resume--otherRaffles-raffle">
                        <img src={otherRaffle1} alt="" />
                    </div>
                    <div className="Content__resume--otherRaffles-raffle">
                        <img src={otherRaffle2} alt="" />
                    </div>
                </div>
            </div>
        </div>
  )
}

export { Resume }