import React from 'react';
import raffleImage from '../../images/raffle-image.png';
import gallery1 from '../../images/gallery-prod-1.png';
import gallery2 from '../../images/gallery-prod-2.png';
import techoIcon from '../../images/techo-icon.png';
import dustPartnerIcon from '../../images/dust2-partner-icon.png';
import facebookIcon from '../../images/facebook-icon.png';
import instagramIcon from '../../images/instagram-icon.png';
import twitterIcon from '../../images/twitter-icon.png';
import xTwitterIcon from '../../images/x-twittter-icon.png';
import tiktokIcon from '../../images/tiktok-icon.png';
import { Link } from 'react-router-dom';

function ProductDetails() {
  return (
        <div className="Content__productDetails">
            <div className="container">
                <div className="Content__productDetails--container">
                    <div className="Content__productDetails--banner">
                        <div className="Content__productDetails--mainImage">
                            <img src={raffleImage} alt="" />
                        </div>
                        <div className="Content__productDetails--gallery">
                            <div className="Content__productDetails--gallery-image">
                                <img src={gallery1} alt="" />
                            </div>
                            <div className="Content__productDetails--gallery-image">
                                <img src={gallery2} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="Content__productDetails--details">
                        <div className="Content__productDetails--date">
                            <p>Fecha de sorteo 23 de Octubre</p>
                        </div>
                        <div className="Content__productDetails--name">
                            <h2>Iphone 13 Rosado 128 GB </h2>
                        </div>
                        <div className="Content__productDetails--tags">
                            <div className="Content__productDetails--tags-tag newProduct">
                                <p>Producto nuevo</p>
                            </div>
                            <div className="Content__productDetails--tags-tag usedProduct">
                                <p>Producto usado</p>
                            </div>
                            <div className="Content__productDetails--tags-tag reconditionedProduct">
                                <p>Producto reacondicionado</p>
                            </div>
                        </div>
                        <div className="Content__productDetails--description">
                            <p>Una pantalla superbrillante en un diseño resistente. Una nueva funcionalidad para grabar videos dignos de Hollywood de una forma superfácil.</p>
                        </div>
                        <div className="Content__productDetails--foundations">
                            <div className="Content__productDetails--foundations-foundation">
                                <img src={techoIcon} alt="" />
                            </div>
                            <div className="Content__productDetails--foundations-foundation">
                                <img src={dustPartnerIcon} alt="" />
                            </div>
                        </div>
                        <div className="Content__productDetails--specs">
                            <div className="Content__productDetails--specs-spec">
                                <p>Pantalla: Pantalla 6”</p>
                            </div>
                            <div className="Content__productDetails--specs-spec">
                                <p>Dos cámaras de 12 MP: gran angular y ultra gran angular</p>
                            </div>
                            <div className="Content__productDetails--specs-spec">
                                <p>Procesador Chip A15 Bionic /  CPU de 6 núcleos </p>
                            </div>
                            <div className="Content__productDetails--specs-spec">
                                <p>Compatible 5G</p>
                            </div>
                            <div className="Content__productDetails--specs-spec">
                                <p>Memoria interna 128 GB</p>
                            </div>
                        </div>
                        <div className="Content__productDetails--numberAmount">
                            <p>Valor del número $10.000</p>
                        </div>
                        <div className="Content__productDetails--chooseNumbers">
                            <button>Escoge los Números aquí</button>
                        </div>
                        <div className="Content__productDetails--availableNumbers">
                            <p>Números disponibles: 3</p>
                        </div>
                        <div className="Content__productDetails--share">
                            <div className="Content__productDetails--share-title">
                                <h4>Comparte sorteo con tus amigos</h4>
                            </div>
                            <div className="Content__productDetails--share-icons">
                                <div className="productDetails__share--icons-icon">
                                    <img src={facebookIcon} alt="" />
                                </div>
                                <div className="productDetails__share--icons-icon">
                                    <img src={instagramIcon} alt="" />
                                </div>
                                <div className="productDetails__share--icons-icon">
                                    <img src={twitterIcon} alt="" />
                                </div>
                                <div className="productDetails__share--icons-icon">
                                    <img src={xTwitterIcon} alt="" />
                                </div>
                                <div className="productDetails__share--icons-icon">
                                    <img src={tiktokIcon} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="Content__productDetails--bePartner">
                            <Link to="/be-partner">
                                <button>¿Quieres ser socio?</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  )
}

export { ProductDetails }