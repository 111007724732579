import React from 'react';
import cardImage from '../images/raffle-image.png';
import { Link } from 'react-router-dom';

function RaffleCard() {
  return (
    <div className="Content__raffles--raffleCard">
        <div className="Content__raffles--raffleCard-foundation">
            <p>Fundación | Techo para chile</p>
        </div>
        <div className="Content__raffles--raffleCard-name">
            <Link to="/single-raffle">
                <h4>Iphone 14 max</h4>
            </Link>
        </div>
        <Link to="/single-raffle">
            <div className="Content__raffles--raffleCard-image">
                <img src={cardImage} alt="" />
            </div>
        </Link>
        <div className="Content__raffles--raffleCard-date">
            <p>Fecha de sorteo</p>
        </div>
        <div className="Content__raffles--raffleCard-numbers">
            <div className="raffles__raffleCard--numbers-number">
                <p>02</p>
            </div>
            <div className="raffles__raffleCard--numbers-number">
                <p>23</p>
            </div>
            <div className="raffles__raffleCard--numbers-number">
                <p>42</p>
            </div>
        </div>
        <div className="Content__raffles--raffleCard-numAmount">
            <Link to="/single-raffle">
                <p>Comprar Número: $10.000</p>
            </Link>
        </div>
        <div className="Content__raffles--raffleCard-availableNumbers">
            <p>Números disponibles 30</p>
        </div>
    </div>
  )
}

export { RaffleCard }