import React from 'react';
import { AvailableNumbers } from '../components/SingleRaffle/AvailableNumbers';
import { ProductDetails } from '../components/SingleRaffle/ProductDetails';
import { Characteristics } from '../components/Home/Characteristics';

function SingleRaffle() {
  return (
    <>
        <ProductDetails />
        <AvailableNumbers />
        <Characteristics />
    </>
  )
}

export { SingleRaffle }