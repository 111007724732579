import React from 'react';
import heartCircle from '../images/heart-circle.png';

function BePartner() {
  return (
        <div className="Content__bePartner">
            <div className="container">
                <div className="Content__bePartner--container">
                    <div className="Content__bePartner--left">
                        <div className="Content__bePartner--icon">
                            <img src={heartCircle} alt="" />
                        </div>
                        <div className="Content__bePartner--title">
                            <h2>¡Hazte socio!</h2>
                        </div>
                        <div className="Content__bePartner--description">
                            <p>Únete a nuestra causa y haz que tu empresa marque la diferencia. Descubre cómo puedes fortalecer tu impacto social al convertirte en socio de nuestra fundación. Juntos, podemos crear un mundo mejor para todos.</p>
                        </div>
                        <div className="Content__bePartner--slogan">
                            <h4>Gana plata y ayuda</h4>
                        </div>
                    </div>
                    <div className="Content__bePartner--form">
                        <form action="">
                            <div className="Content__bePartner--form-text">
                                <p>Ingresa tus datos  y te enviaremos toda la información necesaria para ser socio</p>
                            </div>
                            <div className="Content__bePartner--form-inputField">
                                <div className="Content__bePartner--form-inputContainer">
                                    <label htmlFor="name">Nombre</label>
                                    <input type="text" name="name" id="name" />
                                </div>
                                <div className="Content__bePartner--form-inputContainer">
                                    <label htmlFor="lastName">Apellido</label>
                                    <input type="text" name="lastName" id="lastName" />
                                </div>
                            </div>
                            <div className="Content__bePartner--form-inputContainer">
                                <label htmlFor="email">Email</label>
                                <input type="email" name="email" id="email" />
                            </div>
                            <div className="Content__bePartner--form-inputContainer">
                                <label htmlFor="bussinesArea">Área de tu empresa</label>
                                <input type="text" name="bussinesArea" id="bussinesArea" />
                            </div>
                            <div className="Content__bePartner--form-button">
                                <button>Enviar</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
  )
}

export { BePartner }