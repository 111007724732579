import React from 'react';
import portBanner from '../../images/port-banner.png'

function Portada() {
  return (
    <div className="Content__portada">
        <div className="container">
            <div className="Content__portada--container">
                <div className="Content__portada--details">
                    <div className="Content__portada--details-slogan">
                        <p>Rifas con sentido y seguras</p>
                    </div>
                    <div className="Content__portada--details-title">
                        <h2>Juega, <br /> Monetiza y Apoya</h2>
                    </div>
                    <div className="Content__portada--details-description">
                        <p>Imaginate ganar el producto de tus sueños, ganar dinero apoyando a las personas y cambiar el mundo apoyando fundaciones.</p>
                    </div>
                    <div className="Content__portada--details-buttons">
                        <button>Comienza a participar</button>
                        <button>Infórmate más</button>
                    </div>
                </div>
                <div className="Content__portada--banner">
                    <img src={portBanner} alt="" />
                </div>
            </div>
        </div>
    </div>
  )
}

export { Portada }