import React from 'react';
import heartCircle from '../images/heart-circle.png';
import eyeNo from '../images/eye-no.png';

function Register() {
  return (
        <div className="Content__login">
            <div className="container">
                <div className="Content__login--container">
                    <div className="Content__login--left">
                        <div className="Content__login--left-icon">
                            <img src={heartCircle} alt="" />
                        </div>
                        <div className="Content__login--left-title">
                            <h2>Juega, <br /> Monetiza <br /> y Apoya</h2>
                        </div>
                    </div>
                    <div className="Content__login--form">
                        <div className="Content__login--title">
                            <h2>Registro</h2>
                        </div>
                        <div className="Content__login--button">
                            <button>Regístrate con google</button>
                        </div>
                        <div className="Content__login--separator">
                            <p>Or</p>
                        </div>
                        <div className="Content__login--input">
                            <input type="text" name="name" id="name" placeholder="Ingresa tu nombre" />
                        </div>
                        <div className="Content__login--input" style={{marginBottom: "30px"}}>
                            <input type="password" name="password" id="password" placeholder="Ingresa tu contraseña" />
                            <img src={eyeNo} alt="" />
                        </div>
                        <div className="Content__login--input">
                            <select name="parcipanteType" id="parcipanteType">
                                <option value="">Selecciona el tipo de parcipante</option>
                                <option value="socio">Socio</option>
                                <option value="auspiciador">Auspiciador</option>
                                <option value="usuario">Usuario</option>
                            </select>
                        </div>
                        <div className="Content__login--rememberMe">
                            <div className="Content__login--rememberMe-input">
                                <label htmlFor="rememberMe" className="checkContainer">Recuérdame
                                    <input type="checkbox" name="rememberMe" id="rememberMe" />
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                        </div>
                        <div className="Content__login--bottom">
                            <div className="Content__login--bottom-button">
                                <button>Crear Cuenta</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  )
}

export { Register }