import React from 'react'
import { Portada } from '../components/Home/Portada'
import { Rafflex } from '../components/Home/Rafflex'
import { Testimonials } from '../components/Home/Testimonials'
import { Characteristics } from '../components/Home/Characteristics'

function Home() {
  return (
    <>
        <Portada />
        <Rafflex />
        <Testimonials />
        <Characteristics />
    </>
  )
}

export { Home }