import React from 'react';
import { RaffleCard } from '../RaffleCard';

function Rafflex() {
  return (
    <div className="Content__raffles">
        <div className="container">
            <div className="Content__raffles--container">
                <RaffleCard />
                <RaffleCard />
                <RaffleCard />
                <RaffleCard />
                <RaffleCard />
                <RaffleCard />
            </div>
        </div>
    </div>
  )
}

export { Rafflex }